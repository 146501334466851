import React from "react"
import GetFlagIcon from "./functions/GetFlagIcon"
import { Helmet } from "react-helmet"
import { Trans, useI18next, I18nextContext } from "gatsby-plugin-react-i18next"

// svg imports
import Logo from "./svg/Hero/Logo"
import EnglishFlag from "./svg/Hero/EnglishFlag"
import SerbianFlag from "./svg/Hero/SerbianFlag"
import GetBrandName from "./functions/GetBrandName"

const Header = () => {
  const { t, changeLanguage } = useI18next()
  const LanguageContext = React.useContext(I18nextContext)
  return (
    <div className="container">
      <header className="d-flex justify-content-between py-4">
        {/* meta tags for every page */}
        <Helmet>
          <meta charSet="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
          <meta
            name="Description"
            content={t(
              "Exercise at home or at the gym with our fitness app! Track your workouts, do all the sets and reps and customize exercises!"
            )}
          />
          <meta property="og:type" content="website" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <a href="/">
          {/* Logo */}
          <Logo />
          {/* Brand Name */}
          {/* {LanguageContext.language === "en" ? <BrandNameEn /> : <BrandNameSr />} */}
          {<GetBrandName selectedLang={LanguageContext.language} />}
        </a>
        <div className="dropdown d-inline-block">
          <span
            className="btn text-white btn-primary-outline dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <GetFlagIcon selectedLang={LanguageContext.language} />
          </span>

          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button
                className="dropdown-item"
                href="#"
                onClick={e => {
                  e.preventDefault()
                  changeLanguage("sr")
                }}
              >
                {/* Serbian flag Lang Icon */}
                <SerbianFlag />
                <Trans>Serbian</Trans>
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                href="#"
                onClick={e => {
                  e.preventDefault()
                  changeLanguage("en")
                }}
              >
                {/* English flag lang icon */}
                <EnglishFlag />
                <Trans>English</Trans>
              </button>
            </li>
          </ul>
        </div>
      </header>
    </div>
  )
}
export default Header
