import React from "react";
import EnglishFlag from "../svg/Hero/EnglishFlag";
import SerbianFlag from "../svg/Hero/SerbianFlag";

export default function GetFlagIcon(props) {
  const selectedLang = props.selectedLang;
  if(selectedLang === "en") {
    return <EnglishFlag></EnglishFlag>
  }
  else {
    return <SerbianFlag></SerbianFlag>
  }
}
