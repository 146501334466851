import React from "react"

export default function Facebook() {
  return (
    <svg
      height={40}
      width={40}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#0B2239"
        d="M20 0a20 20 0 1 0 0 40 20 20 0 0 0 0-40Zm5.07 18.1-.33 2.8a.53.53 0 0 1-.53.46h-2.9v8.26a.37.37 0 0 1-.36.38H18a.37.37 0 0 1-.37-.38l.02-8.26h-2.2a.53.53 0 0 1-.52-.53v-2.78a.53.53 0 0 1 .53-.53h2.17v-2.7c0-3.12 1.86-4.82 4.58-4.82h2.23a.53.53 0 0 1 .53.53v2.34a.53.53 0 0 1-.53.53h-1.37c-1.48.02-1.75.73-1.75 1.79v2.33h3.24a.53.53 0 0 1 .5.59Z"
      />
    </svg>
  )
}
