import React from "react"
import BrandNameEn from "./../svg/Hero/BrandNameEn"
import BrandNameSr from "./../svg/Hero/BrandNameSr"

export default function GetBrandName(props) {
  const selectedLang = props.selectedLang
  if (selectedLang === "en") {
    return (
      <>
        {/* English brand name - MyTraining */}
        <BrandNameEn />
      </>
    )
  } else {
    return (
      // Serbian brand name - MojTrening
      <BrandNameSr />
    )
  }
}
