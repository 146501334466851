import React from "react"
// svg imports
import Facebook from "./svg/Footer/Facebook"
import Instagram from "./svg/Footer/Instagram"
import Twitter from "./svg/Footer/Twitter"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

export default function Footer() {
  const { t } = useTranslation()
  return (
    <div className="footer container d-flex flex-column flex-md-row flex-wrap pt-4">
      {/* Newsletter */}
      <div className="col-12 col-md-8 col-lg-5">
        <p className="pt-4">
          <Trans>Newsletter</Trans>
        </p>
        <small className="">
          <Trans>
            Subscribe to our mailing list and stay up to date with the latest
            updates and promotions
          </Trans>
        </small>

        <form className="form-inline d-flex justify-content-between pt-3">
          <div className="form-group col-8">
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail"
              placeholder={t("Your Email")}
            />
          </div>
          <button type="submit" className="btn btn-primary text-secondary">
            <Trans>Subscribe</Trans>
          </button>
        </form>
        <small className="d-block py-3 opacity-75">
          <Trans>
            This site is protected by reCAPTCHA and the Google Privacy
          </Trans>
          <wbr></wbr>
          <Trans>Policy and Terms of Service apply. </Trans>
        </small>
      </div>
      {/* Links */}
      <div className="d-flex col-12 col-md-4 flex-md-column flex-lg-row justify-content-between pt-3 ps-md-5 gap-1 gap-xl-4 mb-4">
        <div className="col-6 col-md-12 col-lg-6">
          <p>
            <Trans>Legal</Trans>
          </p>
          <small>
            <a
              className="text-reset text-decoration-none"
              href="/termsofservice"
            >
              <Trans>Terms of Service</Trans>
            </a>
          </small>{" "}
          <br />
          <small>
            <a className="text-reset text-decoration-none" href="/privacy">
              <Trans>Privacy Policy</Trans>
            </a>
          </small>
        </div>
        <div className="col-6 col-md-12 col-lg-6">
          <p>
            <Trans>Download</Trans>
          </p>
          <small>
            <a
              className="text-reset text-decoration-none"
              href="https://play.google.com/store/apps/details?id=rs.mojtrening"
              target="_blank"
              rel="noreferrer"
            >
              <Trans>Google Play</Trans>
            </a>
          </small>
        </div>
      </div>
      <div className="social-media d-flex flex-column pt-3 col-12 col-md-4 offset-md-8 offset-lg-0 col-lg-3 ps-md-5 ps-lg-0">
      {/* Social media heading  */}
        <p className="col-12 text-center text-md-start text-lg-center">
          <Trans>Follow us:</Trans>
        </p>
        {/* Social Media Icons */}
        <div className="d-flex col-12 gap-4 gap-md-3 gap-xl-3 gap-xxl-4 justify-content-center justify-content-md-start justify-content-lg-center">
          {/* Facebook icon */}
          <a
            href="https://www.facebook.com/ffwdtech"
            target="_blank"
            rel="noreferrer"
          >
            <Facebook></Facebook>
          </a>
          {/* Instagram icon */}
          <a
            href="https://www.linkedin.com/company/ffwd-tech/"
            target="_blank"
            rel="noreferrer"
          >
            <Instagram></Instagram>
          </a>
          {/* Twitter icon */}
          <a
            href="https://twitter.com/ffwdnewmedia"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter></Twitter>
          </a>
        </div>
      </div>
      {/* Copyright notice */}
      <div className="col-12 text-center pt-4 mb-3">
        <small>
          © 2022 Future Forward. <Trans>All Rights Reserved. </Trans>
        </small>
      </div>
    </div>
  )
}
