import React from "react"

export default function Twitter() {
  return (
    <svg
      height={40}
      width={40}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#0B2239"
        d="M20 0a20 20 0 1 0 0 40 20 20 0 0 0 0-40Zm9.96 13.95a8.44 8.44 0 0 1-1.71 1.88.73.73 0 0 0-.27.57v.07a12.22 12.22 0 0 1-3.05 7.99A11.24 11.24 0 0 1 20 27.72c-1.25.4-2.56.6-3.88.6-2.09 0-4.14-.55-5.95-1.6a.34.34 0 0 1-.16-.38.33.33 0 0 1 .32-.25h.5c1.59 0 3.14-.47 4.46-1.35a4.33 4.33 0 0 1-3.42-2.94.23.23 0 0 1 .26-.3c.24.05.48.07.73.07h.09a4.32 4.32 0 0 1-2.52-3.97.23.23 0 0 1 .34-.2c.3.17.64.3.98.38a4.32 4.32 0 0 1-.7-5.24.23.23 0 0 1 .37-.03 11.32 11.32 0 0 0 7.77 4.15h.02a.16.16 0 0 0 .16-.17 4.58 4.58 0 0 1 .1-1.58 4.19 4.19 0 0 1 4.18-3.22 4.3 4.3 0 0 1 2.85 1.07c.1.1.24.14.37.15l.14-.02a7.97 7.97 0 0 0 2.1-.87.22.22 0 0 1 .33.26c-.22.7-.61 1.31-1.13 1.8.47-.1.93-.26 1.37-.46a.23.23 0 0 1 .28.33Z"
      />
    </svg>
  )
}
