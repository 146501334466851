import React from "react"

export default function Instagram() {
  return (
    <svg
      height={40}
      width={40}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 44 44"
    >
      <circle cx="22" cy="22" r="22" fill="#0B2239" />
      <path
        fill="#fff"
        d="M15.62 17.48h-3.19a.76.76 0 0 0-.75.77v13.67c0 .42.34.77.75.77h3.19c.4 0 .74-.35.74-.77V18.25a.76.76 0 0 0-.74-.77Zm-1.6-1.28a3.06 3.06 0 0 0 3.03-3.1c0-1.71-1.36-3.1-3.03-3.1A3.06 3.06 0 0 0 11 13.1c0 1.7 1.35 3.1 3.02 3.1ZM33 31.98c0 .4-.3.7-.69.7H28.9a.7.7 0 0 1-.7-.7v-6.4c0-.97.28-4.2-2.43-4.2-2.1 0-2.53 2.21-2.62 3.2v7.4c0 .4-.3.7-.69.7h-3.3a.7.7 0 0 1-.69-.7v-13.8c0-.38.3-.7.69-.7h3.3a.7.7 0 0 1 .7.7v1.2c.77-1.2 1.93-2.12 4.4-2.12 5.47 0 5.44 5.23 5.44 8.1v6.62Z"
      />
    </svg>
  )
}
